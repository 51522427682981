function isPushEnabled() {
    //---check if push notification permission has been denied by the user---
    if (Notification.permission === 'denied') {
        // alert('User has blocked push notification.')
        return
    }

    //---check if push notification is supported or not---
    if (!('PushManager' in window)) {
        alert('Sorry, Push notification is not supported on this browser.')
        return
    }

    //---get push notification subscription if serviceWorker is registered and ready---
    if(navigator.serviceWorker){
        navigator.serviceWorker.ready
        .then(function (registration) {
            registration.pushManager.getSubscription()
            .then(function (subscription) {
                if (subscription) {
                    //---user is currently subscribed to push---
                    // updatePushNotificationStatus(true)
                    console.log("user is currently subscribed to push")
                }
                else {
                    //---user is not subscribed to push---
                    // updatePushNotificationStatus(false)
                    console.log("user is not subscribed to push")
                }
            })
            .catch(function (error) {
                console.error( 'Error occurred enabling push ', error)
            })
        })
    }
}

export default isPushEnabled