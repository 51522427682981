import React, { lazy } from "react"

import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
import PeopleIcon from "@material-ui/icons/People"
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined"
import LoginContainer from "modules/auth/containers/LoginContainer"
import FlightIcon from '@material-ui/icons/Flight'; 
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SwapVerticalCircleIcon from "@material-ui/icons/SwapVerticalCircle";
import SettingsIcon from '@material-ui/icons/Settings';
import ExposureIcon from '@material-ui/icons/Exposure';

const DashboardContainer = lazy(() => import("modules/dashboard/containers/DashboardContainer"));
const AgentsOverviewContainer = lazy(() => import("modules/agents/containers/AgentsOverviewContainer"))
const OperatorsOverviewContainer = lazy(() => import("modules/operators/containers/OperatorsOverviewContainer"))
const DepositContainer = lazy(() => import("modules/deposit/containers/DepositContainer"))
const DepositDetailsContainer = lazy(() => import("modules/deposit/containers/DepositDetailsContainer"))
const FlightQueueContainer = lazy(() => import("modules/flightQueue/containers/FlightQueueContainer"))
const FlightBookingDetailsContainer = lazy(() => import("modules/flightQueue/containers/FlightBookingDetailsContainer"))
const BookingActionsContainer = lazy(() => import("modules/bookingActions/containers/BookingActionsContainer"))
const BankAccountOverviewContainer = lazy(() => import("modules/bankAccounts/containers/BankAccountOverviewContainer"))
const BookingActionsDetailsContainer = lazy(() => import("modules/bookingActions/containers/BookingActionsDetailsContainer"))
const AgentProfileContainer = lazy(() => import("modules/agents/containers/AgentProfileContainer"))
const LedgersContainer = lazy(() => import("modules/ledgers/containers/LedgerOverviewContainer"))
const SettingsOverviewContainer = lazy(() => import("modules/settings/containers/SettingsOverviewContainer"))
const LedgerAdjustmentsOverviewContainer = lazy(() => import("modules/ledgerAdjustments/containers/LedgerAdjustmentsOverviewContainer"))
const WithoutAuthRoutes = [
    {
        path: "/login",
        exact: true,
        component: LoginContainer,
        title: "Auth",
    },
    {
        path: "/",
        exact: true,
        component: LoginContainer,
        title: "Auth",
    },
]

// AUTH REQUIRED ROUTES
const RoutesOnSidebar = [
    {
        path: "/dashboard",
        exact: true,
        component: <DashboardContainer />,
        title: "Dashboard",
        icon: <DashboardIcon />,
    },
    {
        path: "/agents",
        exact: true,
        component: <AgentsOverviewContainer />,
        title: "Agents",
        icon: <AccountBalanceIcon />,
    },
    {
        path: "/operators",
        exact: true,
        component: <OperatorsOverviewContainer />,
        title: "Operators",
        icon: <PeopleIcon />,
    },
    {
        path: "/deposits",
        exact: true,
        component: <DepositContainer />,
        title: "Deposits",
        icon: <SaveAltOutlinedIcon />,
    },
    {
        path: "/flight-queue",
        exact: true,
        component: <FlightQueueContainer />,
        title: "Flight Queue",
        icon: <FlightIcon />,
    },
    {
        path: "/booking-actions",
        exact: true,
        component: <BookingActionsContainer />,
        title: "Booking Actions",
        icon: <HeadsetMicIcon />,
    },
    {
        path: "/bank-accounts",
        exact: true,
        component: <BankAccountOverviewContainer />,
        title: "Bank Accounts",
        icon: <AccountBalanceWalletIcon />,
    },
    {
        path: "/ledgers",
        exact: true,
        component: <LedgersContainer />,
        title: "Ledgers",
        icon: <SwapVerticalCircleIcon />,
    },
    {
        path: "/ledger-adjustments",
        exact: true,
        component: <LedgerAdjustmentsOverviewContainer />,
        title: "Ledger Adjustment",
        icon: <ExposureIcon />,
    },
    {
        path: "/settings",
        exact: true,
        component: <SettingsOverviewContainer />,
        title: "Settings",
        icon: <SettingsIcon />,
    },
]

const RoutesForAgentProfile = [
    {
        path: "/agent-profile",
        exact: true,
        component: <AgentProfileContainer />,
        title: "Agent Profile",
    }
]

const RoutesForFlightQueue = [
    {
        path: "/flight-booking",
        exact: true,
        component: <FlightBookingDetailsContainer />,
        title: "Flight Booking Details",
    },
];

const RoutesForDeposit = [
    {
        path: "/deposit-details",
        exact: true,
        component: <DepositDetailsContainer />,
        title: "Flight Deposit Details",
    },
];

const RoutesForBookingActions = [
    {
        path: "/booking-actions-details",
        exact: true,
        component: <BookingActionsDetailsContainer />,
        title: "Booking Actions Details",
    },
];

const RoutesNotOnSidebar = []

export { WithoutAuthRoutes, RoutesOnSidebar, RoutesNotOnSidebar, RoutesForDeposit, RoutesForFlightQueue, RoutesForBookingActions, RoutesForAgentProfile }
