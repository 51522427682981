import React, { lazy, Suspense } from 'react'
import { Route, Switch, withRouter } from "react-router-dom"
import { 
    WithoutAuthRoutes, RoutesOnSidebar, RoutesNotOnSidebar, 
    RoutesForDeposit, RoutesForBookingActions, RoutesForFlightQueue, RoutesForAgentProfile } from "routes"
import { makeStyles, useTheme } from "@material-ui/core/styles"
// import InlineLoader from "modules/common/components/InlineLoader"
import * as actions from "actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { AnimatePresence } from "framer-motion"
// import { TransitionGroup } from "react-transition-group"
import CommonSnackBar from "modules/common/components/CommonSnackBar"
import AuthLayout from "layouts/AuthLayout"
// import HomeLayout from "layouts/HomeLayout"
import FullPageLoader from "modules/common/components/FullPageLoader"
import AuthRequired from "modules/auth/components/AuthRequired"
import RehydrationCheckPost from './RehydrationCheckPost'
import ResponsiveApp from './ResponsiveApp'
// import InlineLoader from 'modules/common/components/InlineLoader'
import AppLoaderTriggerComponent from 'modules/common/components/AppLoader'
// import LinearLoader from "modules/common/components/LinearLoader"

const HomeLayout = lazy(() => import("layouts/HomeLayout"))

const pathsForLayout = routes => routes.map(route => route.path)

const createStyles = makeStyles(theme => ({
    [`body`]: {
        fontSize: theme.fontSize
    },
    app: {
        fontFamily: theme.fontFamily,
        color: theme.fontColor,
        boxSizing: "border-box",
        // height: "100vh",
        // overflowY: "auto",
        backgroundColor: theme.backgroundColor,
        "& *": {
            fontFamily: theme.fontFamily,
            // fontSize: `${theme.fontSize} !important`,
        },

        "& .MuiFormLabel-root": {
            backgroundColor: theme.secondaryBackgroundColor,
            // padding: "0 0.25rem"
        },

        "& .primary-color": {
            color: `${theme.primaryColor} !important`
        }
    }
}))

const AuthRequiredRoutes = [ ...RoutesNotOnSidebar, ...RoutesOnSidebar, ...RoutesForDeposit, ...RoutesForBookingActions, ...RoutesForAgentProfile, ...RoutesForFlightQueue ]

const Home = props => {
    const theme = useTheme()
    const classes = createStyles()

    return (
        <div className={classes.app}>
                {/* <InlineLoader/> */}
            <RehydrationCheckPost loader={<FullPageLoader isLoading={true} />} {...props}>
                <ResponsiveApp theme={theme} {...props}>
                    <FullPageLoader {...props}/>
                    <CommonSnackBar {...props} />
                    {/* <LinearLoader/> */}
                    <Switch>
                        <Route exact path={pathsForLayout(WithoutAuthRoutes)}>
                            <AuthLayout {...props}>
                                <Switch>
                                    {
                                        WithoutAuthRoutes.map((route,index) => (
                                            <Route
                                                key={index}
                                                exact={route.exact}
                                                path={route.path}
                                                component={route.component}
                                            />
                                        ))
                                    }
                                </Switch>
                            </AuthLayout>
                        </Route>
                        <Route exact path={pathsForLayout(AuthRequiredRoutes)}>
                            <Suspense fallback={<AppLoaderTriggerComponent {...props}/>}>
                                <HomeLayout>
                                    <AnimatePresence>
                                        <Suspense fallback={<AppLoaderTriggerComponent {...props}/>}>
                                            <Switch>
                                                {
                                                    AuthRequiredRoutes.map((route,index) => (
                                                        <Route
                                                            key={index}
                                                            exact={route.exact}
                                                            path={route.path}
                                                            render={() => (
                                                                <AuthRequired 
                                                                    {...props}
                                                                    orComponent={route.component}
                                                                />
                                                            )}
                                                        />
                                                    ))
                                                }
                                            </Switch>
                                        </Suspense>
                                    </AnimatePresence>
                                </HomeLayout>
                            </Suspense>
                        </Route>
                        <Route component={() => <div>Not Found</div>} />
                        {/*<Redirect to='/404' />*/}
                    </Switch>
                </ResponsiveApp>
            </RehydrationCheckPost>
        </div>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(actions, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))