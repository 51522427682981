import React from "react"
import { createUseStyles, useTheme } from "react-jss"
import Dialog from '@material-ui/core/Dialog'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = createUseStyles(theme => ({
    loaderContainer: {
        // height: "60px",
        width: "15rem",
        backgroundColor: theme.primaryColor,
        position: "relative",
        padding: "10px"
    },
    loaderText: {
        color: "#ffffff",
        fontSize: "small",
        margin: "0.25rem 0"
    },
    loadingBox: {
        padding: "8px 0"
    },
    loadingBar: {
        height: "6px !important"
    }
}))

export default function FullPageLoader(props){
    const theme = useTheme()
    const classes = useStyles({ ...props, theme })
    const { app, isLoading } = props

    return(
        <Dialog 
            aria-labelledby="loader-dialog" 
            open={isLoading || (app && app.loading && app.loading.isActive) ? true : false}>
            <div className={classes.loaderContainer}>
                <div className={classes.loaderText}>{ app && app.loading && app.loading.text ? app.loading.text : "Please Stand by" }</div>
                <div className={classes.loadingBox}>
                    <LinearProgress className={classes.loadingBar}/>
                </div>
            </div>
        </Dialog>
    )
}