export default (number) => {
    if(number){
        let bnCurrencyStr = "", formattedNumber = "";

        bnCurrencyStr = new Intl.NumberFormat('bn').format(number)

        for(var i = 0; i < bnCurrencyStr.length; i++){
            let bnStr = String(bnCurrencyStr.charAt(i))

            if(bnStr === "১") formattedNumber += "1";
            else if(bnStr === "২") formattedNumber += "2";
            else if(bnStr === "৩") formattedNumber += "3";
            else if(bnStr === "৪") formattedNumber += "4";
            else if(bnStr === "৫") formattedNumber += "5";
            else if(bnStr === "৬") formattedNumber += "6";
            else if(bnStr === "৭") formattedNumber += "7";
            else if(bnStr === "৮") formattedNumber += "8";
            else if(bnStr === "৯") formattedNumber += "9";
            else if(bnStr === "০") formattedNumber += "0";
            else formattedNumber += bnStr
        }

        return formattedNumber
    }

    return 0
}