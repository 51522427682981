import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from "react-jss"
import { Provider } from "react-redux" 
import { BrowserRouter as Router } from "react-router-dom"
import './index.css'
import App from './app'
import * as serviceWorker from 'serviceWorker'
import isPushEnabled from "serviceWorker/isPushEnabled"
import configureStore from "./store"
import { Theme } from "config"
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ApolloProvider } from '@apollo/react-hooks'
import apolloClient from "apolloClient"
// import FlightWindowLoader from "modules/common/components/FlightWindowLoader"
import WebFont from 'webfontloader';

// const App = lazy(() => import("client/App"))
import { Font } from '@react-pdf/renderer'
// const App = lazy(() => import("client/App"))

Font.register({
	family: 'Poppins',
	fonts: [
		{ src: '/fonts/poppins-400.ttf', fontStyle: 'normal', fontWeight: 400 },
		{ src: '/fonts/poppins-700.ttf', fontStyle: 'normal', fontWeight: 700 },
	],
});

WebFont.load({
    google: {
        families: ['Poppins:300,400,700', 'sans-serif']
    }
})

const materialTheme = createMuiTheme({
    palette: {
        // type: "dark",
        primary: {
            // light: Theme.primaryColor,
            // dark: Theme.primaryColor,
            main: Theme.primaryColor
        },
        secondary: {
            light: Theme.secondaryColor,
            // dark: Theme.secondaryColor,
            main: Theme.secondaryColor
        },
        text: {
            primary: Theme.fontColor
        },
        success: {
            main: Theme.successColor
        },
        error: {
            main: Theme.errorColor
        },
        warning: {
            main: Theme.warningColor
        }
    },
    typography: {
        fontFamily: Theme.fontFamily,
        fontSize: 12,
        body1: {
            fontSize: "0.8rem",
            "@media (min-width: 960px)": {
                fontSize: "0.8rem"
            },
            // "@media (min-width: 1904px)": {
            //     fontSize: "1.1rem"
            // }
        },
        body2: {
            fontSize: "0.7rem",
            "@media (min-width: 960px)": {
                fontSize: "0.9rem"
            },
            // "@media (min-width: 1904px)": {
            //     fontSize: "1.1rem"
            // }
        }
    }
})

// const customTheme = {
//     ...materialTheme,
//     overrides: {
//         typography: {
//             body2: {
//                 [materialTheme.breakpoints.up("md")]: {
//                     fontSize: "1rem"
//                 }
//             }
//         }
//     }
// }

const { store } = configureStore()

ReactDOM.render(
    
    <Provider store={store}>
        <MuiThemeProvider theme={materialTheme}>
            <ThemeProvider theme={Theme}>
                <ApolloProvider client={apolloClient}>
                    <Router>
                        <App />
                    </Router>
                </ApolloProvider>
            </ThemeProvider>
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

// Check if push notification is enabled
isPushEnabled()
