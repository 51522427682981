import isLocalhost from "./isLocalhost"
import checkValidServiceWorker from "./checkValidServiceWorker"
import registerValidSW from "./registerValidSW"

const appVersion = `v1.0.${Date.now()}`

export function register(config) {
    console.log("process.env.NODE_ENV", process.env.NODE_ENV, 'serviceWorker' in navigator)
    if (
        (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') &&
        'serviceWorker' in navigator
    ) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.REACT_APP_PUBLIC_URL, window.location.href)
    
        console.log("publicUrl.origin", publicUrl.origin, "process.env.REACT_APP_API_ENDPOINT", process.env.REACT_APP_API_ENDPOINT)
    
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets. see https://github.com/facebook/create-react-app/issues/2374
            return
        }
    
        console.log("publicUrl", process.env.REACT_APP_PUBLIC_URL)
    
        window.addEventListener('load', () => {
            const swUrl = `${process.env.REACT_APP_PUBLIC_URL}/service-worker.js?version=${appVersion}`
    
            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config)
        
                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        'This web app is being served cache-first by a service ' +
                        'worker. To learn more, visit https://bit.ly/CRA-PWA'
                    )
                })
            } else {
                // Is not localhost. Just register service worker
                registerValidSW(swUrl, config)
            }
        })
    }
}