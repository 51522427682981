import React, { Component } from "react"
import { useTheme, createUseStyles } from "react-jss"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import CommonTextField from "modules/common/components/CommonTextField"
import Button from "@material-ui/core/Button"
import Grow from "@material-ui/core/Grow"
import { authToken, homePageAfterAuth } from "config"
import CommonImage from "modules/common/components/CommonImage"
import CommonPasswordField from "modules/common/components/CommonPasswordField";
import { wsLink } from "apolloClient"
import { isValidEmail } from "helper"
import { is } from "date-fns/esm/locale";

const useStyles = makeStyles(theme => ({
    heading: {
        color: theme.palette.primary.main
    },
    loginButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "2rem"
    },
    loginButton: {
        width: "8rem",
        color: theme.palette.secondary.main,
        backgroundColor: "#0F718A",

        "&:hover": {
            backgroundColor: "#0a4b5c"
        }
    },
    errorText: {
        color: theme.palette.error.main,
        fontSize: "x-small",
        marginTop: "1rem",
        textAlign: "center"
    },
    loginContainer: {
        height: "100vh",
        padding: "3rem 5rem",
        display: "flex",
        alignItems: "center"
    }
}))

function Login(props){
    const classes = useStyles()
    return props.children(classes)
}

export default class LoginComponent extends Component {
    state = {
        email: "",
        password: "",
        errorMsg: "",
        isErrorOccured: false,
        isLoading: false
    }

    handleChange(value,fieldName){
        this.setState({ [`${fieldName}`] : value, isErrorOccured: false })
    }

    validateAndSubmitData(event){
        if(event) event.preventDefault()

        let isValid = true
        const { email, password } = this.state
        
        if (!email) { isValid = false; this.setState({ errorMsg: 'Email or Password can not be empty' }); }
        else if(!isValidEmail(email)) { isValid = false; this.setState({ errorMsg: 'Your have entered an invalid email' }); }
        if (!password) { isValid = false; this.setState({ errorMsg: 'Email or Password can not be empty' }); }

        if(isValid) this.submitData()
        else this.setState({ isErrorOccured: true })
    }

    async submitData(){
        this.props.actionStartLoading()
        const deviceUuid = await this.props.actionGetDeviceUuid()

        if (deviceUuid) {
            const loginResponse = await this.props.actionLoginOperator({
                auth: {
                    email: this.state.email,
                    deviceUuid
                },
                password: this.state.password
            });

            if (loginResponse && (loginResponse.statusCode === 200 || loginResponse.statusCode === 201)) {
                if (loginResponse.result && loginResponse.result.token) {
                    this.props.actionSetAuthTokens(loginResponse.result);
                    await localStorage.setItem(authToken.key, loginResponse.result.token);
                    wsLink.subscriptionClient.close(true);
                    this.props.history.push(homePageAfterAuth);

                } else {
                    this.setState({ isErrorOccured: true, errorMsg: '' });
                }
            } else {
                 if (loginResponse && loginResponse.message && loginResponse.message === "NOT_A_VALID_USER") {
                    this.setState({ isErrorOccured: true, errorMsg: "We could not find your FlightLocal operator account"})
                } else if (loginResponse && loginResponse.message && loginResponse.message === "PASSWORD_MISMATCH") {
                    this.setState({ isErrorOccured: true, errorMsg: "Username or Password mismatch"})
                } else if (loginResponse && loginResponse.message && loginResponse.message === "MAXIMUM_LOGIN_ATTEMPTS_IS_EXCEEDED") {
                    this.setState({ isErrorOccured: true, errorMsg: "Too many failed login attempts. Try again later"})
                }
                else {
                    this.setState({ isErrorOccured: true, errorMsg: '' });
                }
            }
        } 
        else {
            console.error('UUID NOT GENERATED')
        }

        this.props.actionStopLoading();
    }

    render(){
        const { email, password, errorMsg, isErrorOccured, isLoading } = this.state

        return(
            <Login>
                {classes => (
                    <Paper elevation={0} className={classes.loginContainer}>
                        <form onSubmit={this.validateAndSubmitData.bind(this)}>
                            <div className="text-center">
                                {/* <h4 className={classes.heading}>Welcome Back!</h4> */}
                                <CommonImage
                                    height="40px"
                                    src="images/logo/flight_local_logo.svg" 
                                    alt="flight-local-logo-circle"
                                />
                                <div>Login to your account</div>
                            </div>
                            <div className="mt-5 mx-3">
                                <CommonTextField
                                    label="Email"
                                    value={email}
                                    type="email"
                                    className="mb-3"
                                    required
                                    disabled={isLoading}
                                    onChange={e => this.handleChange(e.target.value,"email")}
                                />
                                <CommonPasswordField
                                    label="Password"
                                    value={password}
                                    required
                                    disabled={isLoading}
                                    onChange={e => this.handleChange(e.target.value,"password")}
                                />
                                <Grow in={isErrorOccured}>
                                    <div className={classes.errorText}>
                                        {errorMsg ? errorMsg: 'Something went wrong, please try again later'}
                                    </div>
                                </Grow>
                                <div className={classes.loginButtonContainer}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        className={classes.loginButton}
                                        // fullWidth
                                        onClick={this.validateAndSubmitData.bind(this)}
                                    >
                                        Login
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Paper>
                )}
            </Login>
            
        )
    }
}