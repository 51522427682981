import registerValidSW from "./registerValidSW"

function checkValidServiceWorker(swUrl, config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
    .then(response => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type')

        if (
            response.status === 404 ||
            (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
            // No service worker found. Probably a different app. Reload the page.
            console.log("No service worker found")
            navigator.serviceWorker.ready.then(registration => {
                registration.unregister().then(() => {
                    window.location.reload()
                })
            })
        } else {
            // Service worker found. Proceed as normal.
            console.log("Service worker found. Proceed as normal.")
            registerValidSW(swUrl, config)
        }
    })
    .catch(() => {
        console.log(
        'No internet connection found. App is running in offline mode.'
        )
    })
}

export default checkValidServiceWorker